<template>
  <s-card class="stats-card pa-4 pa-lg-6 overflow-hidden" style="height: 100%">
    <div class="d-flex justify-space-between align-center">
      <s-text weight="medium" size="md-m">
        {{ title }}
      </s-text>

      <div class="d-flex justify-end align-center">
        <s-btn
          outlined
          elevation="0"
          color="primary"
          small
          @click="refresh()"
          :style="{ fontSize: '14px' }"
        >
          Refresh
        </s-btn>
      </div>
    </div>

    <div class="d-flex content">
      <v-progress-circular
        v-if="isWorking"
        indeterminate
        class="ma-auto"
        color="primary"
        :size="20"
        :width="3"
      />
      <distribution-analytics
        v-else
        v-bind="$attrs"
        :chart-data="statusDistribution"
        :storeKey="key"
        class="mt-10"
      />
    </div>
  </s-card>
</template>

<script>
import { mapState } from 'vuex'
import DistributionAnalytics from '@/components/cards/DistributionAnalytics'
import { removeSpecialChars } from '@/utils/fmt'

export default {
  name: 'DistributionCard',
  components: {
    'distribution-analytics': DistributionAnalytics,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    _key: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isWorking: false,
    }
  },
  computed: {
    ...mapState({
      chartData(state, getters) {
        return getters[`analytics/metricsBy${this._key}`]
      },
    }),
    key: function () {
      return removeSpecialChars(this.$attrs.type)
    },
    statusDistribution: function () {
      return this.chartData
    },
  },
  methods: {
    getDistribution() {
      this.isWorking = true
      this.$store
        .dispatch('analytics/getTopMetrics', {
          id: this.id,
          key: this._key.toLowerCase(),
          params: this.params,
        })
        .finally(() => {
          this.isWorking = false
        })
    },
    refresh() {
      // this.$store.dispatch('analytic/clearAnalytics', this.key)
      this.getDistribution()
    },
  },
  mounted() {
    this.getDistribution()
  },
}
</script>

<style scoped>
.content {
  width: 100%;
  min-height: 290px;
}
</style>
