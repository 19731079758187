<template>
  <s-card class="time_series pa-6 pb-10 bottom-thin-border relative mb-6">
    <div
      class="block_loader d-flex align-center justify-center"
      v-if="isWorking"
    >
      <v-progress-circular
        indeterminate
        class="ma-auto"
        color="primary"
        :size="20"
        :width="3"
      />
    </div>

    <div class="d-flex justify-space-between align-center mb-1">
      <s-text weight="bold" size="md-m">
        {{ title }}
      </s-text>

      <div class="d-flex justify-end align-center">
        <s-btn
          outlined
          elevation="0"
          color="primary"
          class="refresh-btn sm-d-none"
          small
          @click="refresh()"
        >
          Refresh
        </s-btn>
      </div>
    </div>

    <empty-state
      v-if="chartData.length === 0"
      message="No data available"
      hint="Please check back."
      style="height: 350px"
      class="ma-auto"
    />
    <apexchart
      v-else
      type="area"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </s-card>
</template>

<script>
import { mapState } from 'vuex'
import EmptyState from '@/components/cards/EmptyState'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'DataUsageAnalytics',
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    _key: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    apexchart: VueApexCharts,
    'empty-state': EmptyState,
  },
  computed: {
    ...mapState({
      chartData(state, getters) {
        return getters[`analytics/timeSeriesMetricsBy${this._key}`]
      },
    }),
    series: function () {
      if (this.chartData.length === 0) return null
      const tempSeries = this.chartData
      const series = []
      tempSeries.forEach((analytic) => {
        series.push({
          name: analytic.Metric === '{}' ? 'All Data' : analytic.Metric,
          data: analytic.TS.map((seriesValue) => seriesValue.Value.toFixed(4)),
        })
      })
      return series
    },
    xAxis: function () {
      if (this.chartData.length === 0) return null
      // get the times
      const value = this.chartData[0].TS
      const tempTime = value.map((seriesValue) => {
        return new Date(seriesValue.Time * 1000).toISOString()
      })
      return tempTime
    },
    chartOptions: function () {
      return {
        chart: {
          id: 'content-request-chart',
          height: 350,
          type: 'area',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#4caf4f', '#ff4560', '#feb019', '#005199', '#949494'],
        stroke: {
          curve: 'smooth',
        },
        yaxis: {
          title: {
            text: 'Data Usage (MB)',
          },
          labels: {
            // formatter: formatNumber,
            style: this.labelFontStyle,
          },
        },
        xaxis: {
          type: 'datetime',
          categories: this.xAxis,
          // labels: {
          //   style: this.labelFontStyle,
          //   show: this.showAxisLabel,
          // },
          title: {
            text: 'Time',
          },
          axisBorder: {
            show: this.showAxisLabel,
          },
          axisTicks: {
            show: this.showAxisLabel,
          },
          // tooltip: this.showAxisLabel,
        },
        tooltip: {
          x: {
            show: this.showAxisLabel,
            format: 'dd/MM/yy HH:mm',
          },
          style: this.labelFontStyle,
        },
        legend: {
          horizontalAlign: 'right',
          ...this.labelFontStyle,
          labels: {
            colors: this.labelColors,
          },
          markers: {
            width: 10,
            height: 10,
          },
          itemMargin: {
            horizontal: 10,
            vertical: 15,
          },
        },
      }
    },
  },
  data() {
    return {
      filterBy: 'oneDayAgo',
      startDate: '',
      endDate: '',
      isWorking: false,
      labelFontStyle: {
        fontSize: '13px',
        fontWeight: 600,
        fontFamily: 'Questrial, sans-serif',
      },
      labelColors: ['#000'],
    }
  },
  methods: {
    getMetrics() {
      this.isWorking = true
      this.$store
        .dispatch('analytics/getTimeSeriesMetrics', {
          id: this.id,
          key: this._key.toLowerCase(),
          params: this.params,
        })
        .finally(() => {
          this.isWorking = false
        })
    },
    refresh() {
      this.getMetrics()
    },
  },
  mounted() {
    this.getMetrics()
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.time_series {
  height: 450px !important;
  min-height: 450px !important;
}

.block_loader {
  z-index: 10;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(249, 208, 208, 0.2);
  cursor: progress;
  top: 0;
  left: 0;
}

.refresh-btn {
  font-size: $text-sm !important;
}
</style>
