const ranges = [
  { divider: 1e18, suffix: 'E' },
  { divider: 1e15, suffix: 'P' },
  { divider: 1e12, suffix: 'T' },
  { divider: 1e9, suffix: 'G' },
  { divider: 1e6, suffix: 'M' },
  { divider: 1e3, suffix: 'k' },
]

export const formatNumber = (n) => {
  for (let i = 0; i < ranges.length; i++) {
    if (n >= ranges[i].divider) {
      return (n / ranges[i].divider).toString() + ranges[i].suffix
    }
  }
  console.log('holy', n)

  return n
}

export const capitalize = (text) => {
  return `${text.charAt(0).toUpperCase()}${text
    .slice(1)
    .replace(/[\W_-]/g, ' ')}`
}

export const removeSpecialChars = (value) => value.replace(/[^\w\s]/gi, '')

export const safelyGetNumber = (value, defaultValue = 0) =>
  isNaN(value) ? defaultValue : value

export const transformEventStatus = (eventStatus, maps) => {
  /** * Format event based on status
   * Event statuses are formatted into two categories allowed & blocked
   * Default transformation is "blocked"
   */
  switch (maps[eventStatus]) {
    case 'allowed':
    case 'malicious_allowed':
    case 'domain_intelligence_error':
    case 'unclassified':
      return 'Allowed'
    case 'blocked_customer_policy':
    case 'blocked_global_policy':
    case 'blocked_malicious':
    default:
      return 'Blocked'
  }
}
